import { scoreContainer, scoreLabel } from './post-author-score.css';
import { NumericBadge } from '@/components/numeric-badge/numeric-badge.tsx';

export const PostAuthorScore = ({
  label,
  value,
  important,
  hideLabel,
}: {
  label: string;
  value: number;
  important?: boolean;
  hideLabel?: boolean;
}) => {
  return (
    <div className={scoreContainer}>
      {hideLabel ? null : <span className={scoreLabel}>{label}</span>}
      <NumericBadge title={hideLabel ? label : undefined} important={important}>
        {value}
      </NumericBadge>
    </div>
  );
};
