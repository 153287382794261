import { BaseTournamentPostCardFragmentFragment } from '@/__generated__/graphql';
import { useState } from 'react';
import { TournamentPostField } from '@/components/tournament-post-card/tournament-post-fields.tsx';
import {
  predictionTournamentPostCardContent,
  showMoreButton,
} from '@/components/tournament-post-card/prediction-tournament-post-card-content.css.ts';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { CaretArrowDownIcon } from '@synoptic/ui-kit/icons/react/caret-arrow-down.tsx';
import { CaretArrowUpIcon } from '@synoptic/ui-kit/icons/react/caret-arrow-up.tsx';

export const PredictionTournamentPostCardContent = ({
  post,
}: {
  post: BaseTournamentPostCardFragmentFragment;
}) => {
  const [showMore, setShowMore] = useState(false);

  const fields =
    post.fields.length <= 5 || showMore
      ? post.tournament.fields
      : post.tournament.fields.slice(0, 5);

  const fieldValues: { [name: string]: string } = {};

  for (const field of post.fields) {
    fieldValues[field.name] = field.value;
  }

  return (
    <div className={predictionTournamentPostCardContent}>
      {fields.map(({ name, type }, index) => (
        <TournamentPostField
          key={name}
          name={name}
          type={type}
          value={fieldValues[name]}
          authorId={post.author.id}
          index={index}
        />
      ))}
      {post.fields.length > 5 ? (
        <Button
          className={showMoreButton}
          variant="ghost"
          endIcon={!showMore ? <CaretArrowDownIcon /> : <CaretArrowUpIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setShowMore((prev) => !prev);
          }}
        >
          {!showMore ? 'Show all' : 'Collapse'}
        </Button>
      ) : null}
    </div>
  );
};
