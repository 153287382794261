import { useMutation } from '@apollo/client';
import { gql } from '@/__generated__';

const DeletePost = gql(`
  mutation DeletePost($postId: ID!) {
    deletePost(postId: $postId) {
      ... on RegularPost {
        id
        deletedAt
      }
      ... on StreamPost {
        id
        deletedAt
      }
      ... on TournamentPost {
        id
        deletedAt
      }
      ... on RePost {
        id
        deletedAt
      }
    }
  }
`);

export const useDelete = (postId: string) => {
  const [mutate, result] = useMutation(DeletePost);

  const deletePost = () => {
    return mutate({
      variables: { postId },
      optimisticResponse: {
        deletePost: {
          id: postId,
          __typename: 'RegularPost',
          deletedAt: new Date().toISOString(),
        },
      },
    });
  };

  return [deletePost, result] as const;
};
