import { useIsWide } from '@synoptic/ui-kit/responsive/hooks.js';
import { PostCardAuthorFragmentFragment } from '@/__generated__/graphql';
import { PostAuthor } from './post-author';
import { PostAuthorScore } from './post-author-score';
import {
  hiddenPostCardHeader,
  postCardHeader,
  postCardHeaderContent,
  postCardScores,
} from './post-card.css';
import { clsx } from 'clsx';

type PostCardHeaderProps = {
  author: PostCardAuthorFragmentFragment;
  actions?: React.ReactNode;
  isReleased?: boolean;
};
export const PostCardHeader = ({
  actions,
  author,
  isReleased = true,
}: PostCardHeaderProps) => {
  const isWide = useIsWide();
  const hideScoreLabel = !isWide;

  return (
    <div className={clsx(postCardHeader, !isReleased && hiddenPostCardHeader)}>
      <div className={postCardHeaderContent}>
        <PostAuthor author={author} />

        <div className={postCardScores}>
          <PostAuthorScore
            label="Reputation Score"
            value={author.reputationScore}
            important
            hideLabel={hideScoreLabel}
          />
          <PostAuthorScore
            label="Influence Score"
            value={author.influenceScore}
            hideLabel={hideScoreLabel}
          />
          <PostAuthorScore
            label="Vote Power"
            value={author.votePower}
            hideLabel={hideScoreLabel}
          />
        </div>
      </div>

      {actions}
    </div>
  );
};
