import { postCardReposted, postCardRepostedText } from './post-card.css.ts';
import { RepostIcon } from '@synoptic/ui-kit/icons/react/repost.tsx';

export const PostCardRepostAuthor = ({
  fullName,
  releaseAt,
}: {
  fullName: string;
  releaseAt?: string;
}) => {
  return (
    <div className={postCardReposted}>
      <RepostIcon />
      <span className={postCardRepostedText}>
        {fullName} reposted {releaseAt ? 'timed reveal post' : 'this'}
      </span>
    </div>
  );
};
