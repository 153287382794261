import imgPath from '../hidden-post.png';
import { Hint } from '@synoptic/ui-kit/hint/hint.tsx';
import { HelpCircleIcon } from '@synoptic/ui-kit/icons/react/help-circle.tsx';
import {
  Exact,
  PostCardFragmentFragment,
  Scalars,
  StreamPostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { FC, useState } from 'react';
import { calculateTimeLeft } from '@/utils/calculate-time-left.ts';
import { useInterval } from '@/utils/use-interval.ts';
import { postCardContainer } from '../post-card.css.ts';
import {
  hiddenPostCardBackground,
  hiddenPostCardBackgroundContainer,
  hiddenPostCardContainer,
  hiddenPostCardContent,
  hiddenPostCardContentContainer,
  hiddenPostCardCounter,
  hiddenPostCardCounterContainer,
  hiddenPostCardErrorText,
  hiddenPostCardHeaderContainer,
  hiddenPostCardHeaderWithAuthorContainer,
  hiddenPostCardHint,
  hiddenPostCardHintIcon,
  hiddenPostCardHintTitle,
  hiddenPostCardHintTooltip,
  hiddenPostCardLabel,
  hiddenPostCardLabelText,
  hiddenStreamPostCardContainer,
  hiddenStreamPostCardWithAuthorContainer,
} from './hidden-post-card.css.ts';
import { hasTextSelection } from '@/utils/has-text-selection.ts';
import { paths } from '@/routes-utils/paths.ts';
import { LayoutCard } from '../../layout/card.tsx';
import { PostCardActionsMenu } from '../post-card-actions/post-card-actions-menu.tsx';
import { PostCardHeader } from '../post-card-header.tsx';
import { clsx } from 'clsx';
import { useNavigate } from 'react-router';
import { PostCardTopHeader } from '../post-card-top-header.tsx';
import { formatDate } from '@/date-utils/format-date.ts';
import { Spinner } from '@synoptic/ui-kit/spinner/spinner.tsx';
import { streamHiddenPostCardBorder } from '../../stream-post-card/stream-post-card.css.ts';
import { ApolloQueryResult, LazyQueryExecFunction } from '@apollo/client';

type PostType =
  | PostCardFragmentFragment
  | TournamentPostCardFragmentFragment
  | StreamPostCardFragmentFragment;

export type HiddenPostProps = {
  post: PostType;
  linkToPost?: boolean;
  withAuthor?: boolean;
  authenticated?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyFetchPostFunc = LazyQueryExecFunction<any, any>;
type RefetchPostFunc = (
  variables?:
    | Partial<
        Exact<{
          id: Scalars['ID']['input'];
        }>
      >
    | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<ApolloQueryResult<any>>;

type HiddenPostFetchProps = {
  loading: boolean;
  getPost: LazyFetchPostFunc;
  refetch: RefetchPostFunc;
};

const formatTimeLeft = (timeLeft: {
  hours?: number;
  minutes?: number;
  seconds?: number;
}) => {
  if (!Object.keys(timeLeft).length) return <Spinner />;

  const hours = `${(timeLeft?.hours as number) < 10 ? '0' + timeLeft?.hours : timeLeft?.hours}`;
  const minutes = `${(timeLeft?.minutes as number) < 10 ? '0' + timeLeft?.minutes : timeLeft?.minutes}`;
  const seconds = `${(timeLeft?.seconds as number) < 10 ? '0' + timeLeft?.seconds : timeLeft?.seconds}`;

  return (timeLeft?.hours as number) > 0
    ? `${hours}:${minutes}:${seconds} hours`
    : `${minutes}:${seconds} mins`;
};

export const HiddenPostCard: FC<HiddenPostProps & HiddenPostFetchProps> = ({
  post,
  linkToPost,
  withAuthor,
  getPost,
  refetch,
  loading,
  authenticated = true,
}) => {
  const { id, released, releaseAt, deletedAt, author, __typename } = post;

  const isStreamPost = __typename === 'StreamPost';
  const isReleased = Boolean(released);
  const isDeleted = Boolean(deletedAt);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(releaseAt ?? ''));
  const navigate = useNavigate();

  const [unexpectedError, setUnexpectedError] = useState(false);

  useInterval(() => {
    if (!isReleased) {
      const timeLeftTmp = calculateTimeLeft(releaseAt ?? '');
      setTimeLeft(timeLeftTmp);
      if (
        Object.values(timeLeftTmp).every((value) => value === 0) ||
        !Object.keys(timeLeftTmp).length
      ) {
        setTimeout(() => {
          getPost({ fetchPolicy: 'network-only' }).then(
            ({ data }: { data: { post?: PostType } }) => {
              if (
                data &&
                (data.post?.__typename === 'TournamentPost' ||
                  data.post?.__typename === 'StreamPost' ||
                  data.post?.__typename === 'RegularPost') &&
                !data.post?.released
              ) {
                refetch().then(({ data }: { data: { post?: PostType } }) => {
                  if (
                    data &&
                    (data.post?.__typename === 'TournamentPost' ||
                      data.post?.__typename === 'StreamPost' ||
                      data.post?.__typename === 'RegularPost') &&
                    !data.post?.released
                  ) {
                    setUnexpectedError(true);
                  }
                });
              }
            },
          );
        }, 500);
      }
    }
  }, 1000);

  return (
    <div className={isStreamPost ? streamHiddenPostCardBorder : ''}>
      <LayoutCard
        data-testid={'post-card'}
        className={clsx(
          postCardContainer,
          hiddenPostCardContainer,
          isStreamPost && hiddenStreamPostCardContainer,
          isStreamPost && withAuthor && hiddenStreamPostCardWithAuthorContainer,
        )}
        hoverable={linkToPost}
        data-deleted={isDeleted ? '' : undefined}
        onClick={(e) => {
          const isSelecting = hasTextSelection();
          if (!isSelecting) {
            if (linkToPost) {
              navigate(paths.makePost(id));
            }
          } else {
            e.preventDefault();
          }
        }}
        role={linkToPost ? 'link' : 'article'}
        tabIndex={linkToPost ? 0 : undefined}
      >
        {withAuthor && (
          <div
            className={clsx(
              hiddenPostCardHeaderContainer,
              isStreamPost && hiddenPostCardHeaderWithAuthorContainer,
            )}
          >
            {authenticated ? <PostCardTopHeader post={post} /> : null}
            <PostCardHeader
              isReleased={false}
              author={{
                ...author,
                following: authenticated ? author.following : false,
              }}
              actions={
                authenticated && (
                  <PostCardActionsMenu
                    author={post.author}
                    id={post.id}
                    deletedAt={post.deletedAt}
                  />
                )
              }
            />
          </div>
        )}

        <div className={hiddenPostCardContentContainer}>
          <div className={hiddenPostCardBackgroundContainer}>
            <img
              src={imgPath}
              alt="blured background"
              draggable={false}
              className={hiddenPostCardBackground}
            />
          </div>
          <div className={hiddenPostCardContent}>
            <div className={hiddenPostCardLabel}>
              <span className={hiddenPostCardLabelText}>
                Will be revealed in
              </span>
              <Hint
                className={hiddenPostCardHintTooltip}
                trigger={<HelpCircleIcon className={hiddenPostCardHintIcon} />}
              >
                <div className={hiddenPostCardHint}>
                  <p>
                    This post will reveal on{' '}
                    {formatDate(new Date(releaseAt ?? ''))}
                  </p>
                  <p className={hiddenPostCardHintTitle}>
                    What is timed reveal post?
                  </p>
                  <p>
                    A timed reveal post stays hidden until a set time, then
                    shows up for everyone.
                  </p>
                </div>
              </Hint>
            </div>
            <div className={hiddenPostCardCounterContainer}>
              <span className={hiddenPostCardCounter}>
                {loading ? <Spinner /> : formatTimeLeft(timeLeft)}
              </span>
            </div>
            {unexpectedError && (
              <span className={hiddenPostCardErrorText}>
                Something went wrong. <br /> Reload page to see post content!
              </span>
            )}
          </div>
        </div>
      </LayoutCard>
    </div>
  );
};
