import { gql } from '@/__generated__';

export const RepostCardFragment = gql(`
fragment RepostCardFragment on RePost {
  id
  createdAt
  originalPost {
    ...PostCardFragment
  }
  author {
    id
    ...UserBadgeFragment
    influenceScore
    reputationScore
    votePower
    following
  }
  __typename
}
`);
