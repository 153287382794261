import { useLazyQuery } from '@apollo/client';
import { FC } from 'react';
import {
  HiddenPostCard,
  HiddenPostProps,
} from '../hidden-post-card/hidden-post-card.tsx';
import { gql } from '@/__generated__/index.ts';

const PostQuery = gql(`
  query PostQuery($id: ID!) {
    post(id: $id) {
      ... on RegularPost {
        ...PostCardFragment
      }
      ... on TournamentPost {
        ...TournamentPostCardFragment
      }
      ... on StreamPost {
        ...StreamPostCardFragment
      }
    }
  }
`);

export const HiddenPost: FC<Omit<HiddenPostProps, 'authenticated'>> = ({
  post,
  linkToPost,
  withAuthor,
}) => {
  const [getPost, { loading, refetch }] = useLazyQuery(PostQuery, {
    variables: { id: post.id },
  });

  return (
    <HiddenPostCard
      post={post}
      linkToPost={linkToPost}
      withAuthor={withAuthor}
      loading={loading}
      getPost={getPost}
      refetch={refetch}
    />
  );
};
