import { useMutation } from '@apollo/client';
import { gql } from '@/__generated__/index.ts';
import { FeedScope, RePostInput } from '@/__generated__/graphql.ts';
import { RepostCardFragment } from './repost-card-fragment.ts';
import { useMe } from '@/routes/_app/use-me.ts';
import { updateFeedCache } from '@/apollo-cache-utils/update-feed-cache.ts';

const RePost = gql(`
  mutation Repost($input: RePostInput!) {
    repost(input: $input) {
      ...RepostCardFragment
    }
  }
`);

export const useRePost = (input: RePostInput) => {
  const [rePostMutation, result] = useMutation(RePost);
  const me = useMe();

  const rePost = () => {
    return rePostMutation({
      variables: { input },
      update(cache, { data }) {
        const addPost = data?.repost;
        if (addPost) {
          cache.modify({
            fields: {
              feed(feedPage, { storeFieldName }) {
                if (storeFieldName.includes(FeedScope.FeedFollowing)) {
                  const newPostRef = cache.writeFragment({
                    fragment: RepostCardFragment,
                    fragmentName: 'RepostCardFragment',
                    data: addPost,
                  });

                  return updateFeedCache(feedPage, newPostRef);
                }
                return feedPage;
              },
            },
          });
          cache.modify({
            id: `User:${me.id}`,
            fields: {
              posts(postsPage) {
                const newPostRef = cache.writeFragment({
                  fragment: RepostCardFragment,
                  fragmentName: 'RepostCardFragment',
                  data: addPost,
                });

                return updateFeedCache(postsPage, newPostRef);
              },
            },
          });
        }
      },
    });
  };

  return [rePost, result] as const;
};

const UnRePost = gql(`
  mutation UnRepost($repostId: ID!) {
    unRepost(repostId: $repostId)
  }
`);

export const useUnRePost = (repostId: string) => {
  const me = useMe();

  const [unRePostMutation, result] = useMutation(UnRePost, {
    update(cache, { data }) {
      const repostRef = cache.identify({
        id: repostId,
        __typename: 'RePost',
      });
      const unRepost = data?.unRepost;
      if (unRepost) {
        cache.modify({
          fields: {
            feed(feedPage, { storeFieldName }) {
              if (!storeFieldName.includes(FeedScope.FeedStream)) {
                const idx = feedPage.items.findIndex(
                  (item: { __ref: string }) => item.__ref === repostRef,
                );
                return {
                  ...feedPage,
                  items: feedPage.items
                    .slice(0, idx)
                    .concat(feedPage.items.slice(idx + 1)),
                };
              }
              return feedPage;
            },
          },
        });
        cache.modify({
          id: `User:${me.id}`,
          fields: {
            posts(postsPage) {
              const idx = postsPage.items.findIndex(
                (item: { __ref: string }) => item.__ref === repostRef,
              );
              return postsPage.items
                .slice(0, idx)
                .concat(postsPage.items.slice(idx + 1));
            },
          },
        });
      }
    },
  });

  const unRePost = () => {
    return unRePostMutation({
      variables: { repostId },
      optimisticResponse: {
        unRepost: true,
      },
    });
  };

  return [unRePost, result] as const;
};
