import {
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
  StreamPostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { useMe } from '../../routes/_app/use-me.ts';
import { PostCardHiddenLabel } from './post-card-hidden-label.tsx';
import { PostCardRepostAuthor } from './post-card-repost-author.tsx';
import {
  postCardRepostedLink,
  postCardTopHeaderContainer,
} from './post-card.css.ts';

export const PostCardTopHeader = ({
  post,
}: {
  post:
    | PostCardFragmentFragment
    | RepostCardFragmentFragment
    | TournamentPostCardFragmentFragment
    | StreamPostCardFragmentFragment;
}) => {
  const me = useMe();

  const postContent =
    (post as RepostCardFragmentFragment)?.originalPost || post;

  const isRepost = post.__typename === 'RePost';
  const isMyRepost = isRepost && post.author?.id === me.id;

  return (
    <div className={postCardTopHeaderContainer}>
      {isRepost &&
        (isMyRepost ? (
          <PostCardRepostAuthor
            fullName={'You'}
            releaseAt={postContent.releaseAt ?? ''}
          />
        ) : (
          <Link
            to={paths.makeUser(post.author.username)}
            onClick={(e) => e.stopPropagation()}
            className={postCardRepostedLink}
          >
            <PostCardRepostAuthor
              fullName={post.author.fullName}
              releaseAt={postContent.releaseAt ?? ''}
            />
          </Link>
        ))}
      {!isRepost && postContent.releaseAt && <PostCardHiddenLabel />}
    </div>
  );
};
