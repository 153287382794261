import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@synoptic/ui-kit/dropdown-menu/dropdown-menu.js';
import { LinkIcon } from '@synoptic/ui-kit/icons/react/link.js';
import {
  PostCardFragmentFragment,
  RegularPostInput,
  StreamPostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { paths } from '@/routes-utils/paths.ts';
import { copyUrlToClipboard } from '@/utils/copy-url-to-clipboard.ts';
import { useState } from 'react';
import {
  ModalContent,
  ModalRoot,
  ModalTrigger,
} from '@synoptic/ui-kit/modal/modal.js';
import { useToast } from '@synoptic/ui-kit/toast/toast-provider.js';
import { ShareIcon } from '@synoptic/ui-kit/icons/react/share.tsx';
import { PostTagButton } from '@synoptic/ui-kit/post-tag-button/post-tag-button.js';
import { RepostIcon } from '@synoptic/ui-kit/icons/react/repost.tsx';
import { QuoteIcon } from '@synoptic/ui-kit/icons/react/quote.tsx';
import { useRePost, useUnRePost } from '../use-repost.ts';
import { NewPostForm } from '../../new-post-modal/form.tsx';
import { useAddPost } from '../../new-post-modal/use-add-post.tsx';

export const PostCardShareActionsMenu = ({
  post,
  isMyRepost,
  repostId,
  quoteAllowed = true,
}: {
  post:
    | PostCardFragmentFragment
    | StreamPostCardFragmentFragment
    | TournamentPostCardFragmentFragment;
  isMyRepost?: boolean;
  repostId?: string | null;
  quoteAllowed?: boolean;
}) => {
  const [showQuotePost, setShowQuotePost] = useState(false);
  const [rePost] = useRePost({ originalPostId: post.id });
  const [unRePost] = useUnRePost(repostId ?? '');
  const [addPost] = useAddPost();
  const toast = useToast();
  const canRepost = repostId !== undefined;

  const actions = [
    isMyRepost ? (
      <DropdownMenuItem
        key="undo-re-post"
        icon={<RepostIcon />}
        onSelect={() => {
          unRePost().then(() => {
            toast.info({ title: 'Post removed from your feed' });
          });
        }}
      >
        Undo Repost
      </DropdownMenuItem>
    ) : canRepost ? (
      <DropdownMenuItem
        key="re-post"
        icon={<RepostIcon />}
        onSelect={() => {
          rePost().then(() => {
            toast.info({ title: 'Post shared to your feed' });
          });
        }}
      >
        Repost
      </DropdownMenuItem>
    ) : null,

    quoteAllowed ? (
      <ModalTrigger key="quote" asChild>
        <DropdownMenuItem
          onClick={(e) => e.stopPropagation()}
          onSelect={() => setShowQuotePost(true)}
          icon={<QuoteIcon />}
        >
          Quote
        </DropdownMenuItem>
      </ModalTrigger>
    ) : null,

    <DropdownMenuItem
      key="copy-link"
      icon={<LinkIcon />}
      onSelect={() => {
        copyUrlToClipboard(paths.makePost(post.id));
        toast.info({ title: 'Link copied to clipboard' });
      }}
    >
      Copy link
    </DropdownMenuItem>,
  ];

  return (
    <ModalRoot open={showQuotePost} onOpenChange={setShowQuotePost}>
      <DropdownMenuRoot>
        <DropdownMenuTrigger>
          <PostTagButton startIcon={<ShareIcon />}>Share</PostTagButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent>{actions}</DropdownMenuContent>
      </DropdownMenuRoot>

      <ModalContent onClick={(e) => e.stopPropagation()}>
        <NewPostForm
          titleText={'Quote post'}
          onSubmit={async (input) => {
            await addPost(input as RegularPostInput);
            setShowQuotePost(false);
          }}
          post={post}
        />
      </ModalContent>
    </ModalRoot>
  );
};
