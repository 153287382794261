import { useEffect } from 'react';
import { useCallbackRef } from '@synoptic/ui-kit/utils/use-callback-ref.tsx';

export const useInterval = (callback: () => void, delay: number) => {
  const cb = useCallbackRef(callback);

  useEffect(() => {
    function tick() {
      cb();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [cb, delay]);
};
