import { TournamentPostCardFragmentFragment } from '@/__generated__/graphql';
import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths';
import { useNavigate } from 'react-router';
import { EmptyState } from '@synoptic/ui-kit/empty-state/empty-state.js';
import { ChatBubbleOutlineIcon } from '@synoptic/ui-kit/icons/react/chat-bubble-outline.js';
import { TrashIcon } from '@synoptic/ui-kit/icons/react/trash.js';
import { PostTagButton } from '@synoptic/ui-kit/post-tag-button/post-tag-button.js';
import { formatDate } from '../../date-utils/format-date';
import { formatDateWithMs } from '../../date-utils/format-date-with-ms';
import { LayoutCard } from '../layout/card';
import { HiddenPost } from '../post-card/hidden-post/hidden-post';
import { PostCardActionsMenu } from '../post-card/post-card-actions/post-card-actions-menu';
import { PostCardShareActionsMenu } from '../post-card/post-card-actions/post-card-share-actions-menu';
import { PostVoteButton } from '../post-card/post-card-actions/post-vote-button';
import { PostCardHeader } from '../post-card/post-card-header';
import {
  postCardActions,
  postCardContainer,
  postCardContent,
  postCardDate,
  postCardDeletedPlaceholder,
  postCardFooter,
  postCardLabels,
} from '../post-card/post-card.css';
import { TournamentLabel } from '../post-label/tournament-label';
import { TournamentPostCardContent } from './tournament-post-card-content';
import { PostCardHiddenLabel } from '@/components/post-card/post-card-hidden-label.tsx';
import {
  TournamentType,
  determinateTournamentType,
} from '@/components/tournaments/determinate-tournament-type';
import { PredictionTournamentPostCardContent } from '@/components/tournament-post-card/prediction-tournament-post-card-content.tsx';

type TournamentPostCardProps = {
  post: TournamentPostCardFragmentFragment;
  linkToPost?: boolean;
};
export const TournamentPostCard = ({
  post,
  linkToPost,
}: TournamentPostCardProps) => {
  const tournamentType = determinateTournamentType(post.tournament.fields);

  const navigate = useNavigate();

  const isDeleted = Boolean(post.deletedAt);
  const isReleased = post.released;

  return isReleased ? (
    <LayoutCard
      className={postCardContainer}
      hoverable={linkToPost}
      data-deleted={isDeleted ? '' : undefined}
      onClick={() => linkToPost && navigate(paths.makePost(post.id))}
      role={linkToPost ? 'link' : undefined}
      tabIndex={linkToPost ? 0 : undefined}
    >
      {post.releaseAt && <PostCardHiddenLabel />}
      <PostCardHeader
        author={post.author}
        actions={
          <PostCardActionsMenu
            author={post.author}
            id={post.id}
            deletedAt={post.deletedAt}
          />
        }
      />

      <div className={postCardContent}>
        {isDeleted ? (
          <EmptyState
            illustration={<TrashIcon />}
            heading="The post was deleted"
            className={postCardDeletedPlaceholder}
          />
        ) : tournamentType === TournamentType.Prediction ? (
          <PredictionTournamentPostCardContent post={post} />
        ) : (
          <TournamentPostCardContent post={post} />
        )}

        <time
          title={formatDateWithMs(new Date(post.createdAt))}
          dateTime={post.createdAt}
          className={postCardDate}
        >
          {formatDate(new Date(post.createdAt))}
        </time>
      </div>

      <div className={postCardFooter}>
        <div className={postCardLabels} onClick={(e) => e.stopPropagation()}>
          <TournamentLabel
            label={post.tournament.label}
            tournamentId={post.tournament.id}
            linkToTournament
          />
        </div>
        <div className={postCardActions} onClick={(e) => e.stopPropagation()}>
          <PostVoteButton post={post} disabled={isDeleted} />

          <PostTagButton startIcon={<ChatBubbleOutlineIcon />} asChild>
            <Link to={paths.makePost(post.id)}>{post.commentCount}</Link>
          </PostTagButton>

          <PostCardShareActionsMenu post={post} />
        </div>
      </div>
    </LayoutCard>
  ) : (
    <HiddenPost post={post} withAuthor />
  );
};
